import React, { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import ContactUs from './ContactUs';
import Aos from 'aos';
import 'aos/dist/aos.css';
const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
    background: 'transparent',
    border: 'none',
  }


})
);
function toggleMenu() {
  const menu = document.getElementById("mobileNavContainer")!;
  menu.classList.toggle("open-menu");
  console.log("pressed");

}
function Auctions() {
  let navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classes = useStyles();
  return (
    <div>
     <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>

          <li>
            <a onClick={() => {
              navigate("/");
            }}>Home</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/talent-agency");
            }}>Talent Agency</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/marketplace");
            }}>Marketplace</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/auctions");
            }}>Auctions</a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://twitter.com/CyclopesSolana" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
            
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <a href="/"><img className="nav-logo" src="/img/logo.png" alt="" /></a>
          <a className ="hide-800" onClick={() => {
            navigate("/");
          }}>Home</a>
          <a className ="hide-800" onClick={() => {
            navigate("/talent-agency");
          }}>Talent Agency</a>
          <a className ="hide-800" onClick={() => {
            navigate("/marketplace");
          }}>Marketplace</a>
          <a className ="hide-800" onClick={() => {
            navigate("/auctions");
          }}>Auctions</a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com/CyclopesSolana" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
          

          </div>
        </div>
      </nav>
      <div className="content-wrapper">
        <div className="introduction soon">


          <div className="introduction-text soon">
                        <h1>Coming soon</h1>

                    </div>
         

          </div>
      </div>

     
    </div>
  );
}
export default Auctions;