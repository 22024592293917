import "./App.css";
import React from "react"
import HomePage from './homepage'
import TalentAgency from './upcoming'
import Marketplace from "./marketplace";
import Auctions from "./auctions";
import { Routes, Route, HashRouter } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
function toggleMenu() {
  const menu = document.getElementById("mobileNavContainer")!;
  menu.classList.toggle("open-menu");
  console.log("pressed");
  
}
function App() {
  
  return <div className="App">
     <HashRouter>
      <Routes>
        <Route path="/talent-agency" element={<TalentAgency />}/>
        <Route path="/" element={<HomePage />}/>
        <Route path="/marketplace" element={<Marketplace/>}/>
        <Route path="/auctions" element={<Auctions/>}/>
      </Routes>
    </HashRouter>
     
      
  </div>
}

export default App;
