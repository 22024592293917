import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './App.css';
export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_h2vfjiq', 'template_jmbph5f', form.current, 'R6OZkZWZ0VE-u4Bo0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
        <h1>Contact Us</h1>
      <div className="block"><div><label>Name: </label></div>
      <input type="text" name="user_name" /></div>
      <div className="block"><div><label>Email: </label></div>
      <input type="email" name="user_email" /></div>
      <div className="block"><div><label>Social Media: </label></div>
      <input type="text" name="user_social" /></div>
      <div><label>Portfolio: </label>
      <textarea name="message" /></div>
      <div><label>Tell us about yourself: </label>
      <textarea name="message" /></div>
      <input className='sendbutton' type="submit" value="Send" />
    </form>
  );
};
export default ContactUs;