import "./App.css";
import React, { useMemo, useState, useRef, useEffect } from "react";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { makeStyles } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { whitelistSettings } from "./userSettings";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'black',
    background: 'transparent',
    border: 'none',
  }


})
);

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const HomePage = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const classes = useStyles();
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }
  let navigate = useNavigate();
  useEffect(()=>{
      Aos.init({duration: 1000});
  }, []);
  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>

          <li>
            <a onClick={() => {
              navigate("/");
            }}>Home</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/talent-agency");
            }}>Talent Agency</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/marketplace");
            }}>Marketplace</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/auctions");
            }}>Auctions</a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://twitter.com/CyclopesSolana" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
            
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <a href="/"><img className="nav-logo" src="/img/logo.png" alt="" /></a>
          <a className ="hide-800" onClick={() => {
            navigate("/");
          }}>Home</a>
          <a className ="hide-800" onClick={() => {
            navigate("/talent-agency");
          }}>Talent Agency</a>
          <a className ="hide-800" onClick={() => {
            navigate("/marketplace");
          }}>Marketplace</a>
          <a className ="hide-800" onClick={() => {
            navigate("/auctions");
          }}>Auctions</a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com/CyclopesSolana" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
          

          </div>
        </div>
      </nav>

      <div className="content-wrapper">

        <div className="introduction">

          <div className="introduction-text">
            <h1>Solana Cyclopes</h1>
            <hr className="introduction-hr"></hr>
            <br></br>
            <p>777 Cyclopes unleashed on the Solana Blockchain. We strive to build an ecosystem around the project, including staking and breeding, along with multiple utility aspects revolving around 1/1 art, such as a scalable platform featuring auctions and 1/1 art marketplace along with an Art Talent Agency. More information on the tokenomics, funds distribution and technical aspects can be found in the whitepaper.</p>
            <div className="whitepaper-button">
              <a className="whitepaper" href="whitepaper.pdf" target="_blank">Whitepaper</a>
            </div>
          </div>

          <div className="gallery">
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/1.gif"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/1.png"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/2.gif"></img>

                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/2.png"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/3.gif"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/3.png"></img>

                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/4.gif"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/4.png"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/5.gif"></img>

                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="ecosystem">
          <h1>Ecosystem</h1>
          <div className="ecosystem-container">
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className="container-hover">
                  <img src="/assets/beast1.png"></img>
                  <div className="overlay">
                    <div className="text">Staking</div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className="container-hover">
                  <img src="/assets/beast2.png"></img>
                  <div className="overlay">
                    <div className="text">Breeding</div>
                  </div>
                </div>

              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className="container-hover">
                  <img src="/assets/beast3.png"></img>
                  <div className="overlay">
                    <div className="text">Revenue sharing</div>
                  </div>
                </div>

              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className="container-hover">
                  <img src="/assets/beast4.png"></img>
                  <div className="overlay">
                    <div className="text">Art Talent Agency</div>
                  </div>
                </div>

              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className="container-hover">
                  <img src="/assets/beast5.png"></img>
                  <div className="overlay">
                    <div className="text">1/1 art auction platform and marketplace</div>
                  </div>
                </div>

              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <div className="container-hover">
                  <img src="/assets/beast6.png"></img>
                  <div className="overlay">
                    <div className="text">DAO</div>
                  </div>
                </div>

              </Paper>
            </Grid>
          </Grid>
          </div>
        </div>

        <div className="roadmap">
          <h1>Roadmap 2.0</h1>

          <div className="container roadmap">

            <div>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#d37e14', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #d37e14' }}
                  date="Minting phase"
                  iconStyle={{ background: '#d37e14', color: '#fff' }}
                //icon={<WorkIcon />}
                >

                  <ul>
                    <li>On the minting day, 777 cyclopes will be unleashed on the solana blockchain</li>
                    <li>Each cyclops has special characteristics that will become known to all</li>
                    <li>Pre-approved ME listing shortly after</li>
                  </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#d37e14', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #d37e14' }}
                  date="Treasury and infrastructure"
                  iconStyle={{ background: '#d37e14', color: '#fff' }}
                //icon={<WorkIcon />}
                >

                  <ul>
                    <li>Verification/sales/listing bots will be live shortly after mint</li>
                    <li>Setting up the treasury with 50% of the royalties to fund it along with 20% of the mint proceeds</li>
                    <li> Treasury fund distribution plan in the whitepaper</li>
                  </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#d37e14', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #d37e14' }}
                  date="Staking and Breeding"
                  iconStyle={{ background: '#d37e14', color: '#fff' }}
                //icon={<WorkIcon />}
                >

                  <ul>
                    <li>Tokenomics in the whitepaper</li>
                    <li>Partnership with Ubik on the staking contract, we will start with the basic staking contract which will be substituted to a custom one</li>
                    <li>Gen 2 Cyclopes breeding for $SHEEP</li>
                  </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="Talent Agency"
                  contentArrowStyle={{ borderRight: '7px solid  #d37e14' }}
                  contentStyle={{ background: '#d37e14', color: '#fff' }}
                  iconStyle={{ background: '#d37e14', color: '#fff' }}
                //icon={<WorkIcon />}
                >
                  <ul>
                    <li>Ahead of the launch of our 1/1 auction platform we will start working with artist to help with their marketing, social media management and tech</li>
                    <li>We will provide unique utilities for the artists launched with us</li>
                    <li>25% of the revenue will be added to $SHEEP LP and 25% to Solana Cyclopes holders</li>
                  </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="Platform"
                  contentArrowStyle={{ borderRight: '7px solid  #d37e14' }}
                  contentStyle={{ background: '#d37e14', color: '#fff' }}
                  iconStyle={{ background: '#d37e14', color: '#fff' }}
                //icon={<WorkIcon />}
                >
                  <ul>
                    <li>Full fledged auction and market place tailored to the needs of the creators</li>
                    <li>Artists will be able to hold auctions along with the secondary sales of their pieces</li>
                    <li>We expect the artists from our talent agency to be exclusive with</li>
                  </ul>
                </VerticalTimelineElement>
              </VerticalTimeline>

            </div>
          </div>
        </div>

        <div className="lore">
          <h1>Lore</h1>
          <hr></hr>
          <div className="lore-chapters">
            <div className="chapter">
              <h3 data-aos="fade-left">1. Origin</h3>
              <p data-aos = "fade-up">In the world of the Helios, two races lived among each other. The Mortals and
                the Cyclopes, The Mortals, were the biggest amongst its race. They occupy
                most of the land, which was appropriately named the Lowlands due to how it
                has more ground and more options to expand. The Mortals were ingenious
                people, learning solidarity and developing magic progressively. The second
                race is the Cyclopes’, a race of giant stature and its defining feature: a
                single eye. They were few, but they had a double life span compared to the
                Mortals. Due to their giant figure, the Cyclopes’ courteously volunteered to
                reside amongst high mountains and valleys, appropriately named the Highlands.
                Just like the Mortals, they were adamantly gifted in magic. But they also
                have superiority in blacksmithing and superior strength. Their cities were
                built with magnificent heights, sophisticated carving, and towering walls that
                pale in comparison to the Mortal kingdoms. It was clear as day on who is the
                superior race. Yet, the Cyclopes were humble, sharing their craft, opening
                their doors to their kingdom, and wanting nothing more but peace and unity.
                It was like this until the Mortal mages developed a curse so powerful it was
                enough to change the Highlander’s stance and their very nature. A curse
                of imprisonment.</p>
            </div>
            <div className="chapter">
              <h3 data-aos="fade-left">2. Curse</h3>
              <p data-aos="fade-up">There was no denying the Cyclopes’ magic is superior. They are far more
                advanced than the Mortals are doing and far more complicated than the
                human mind can understand. There were theories that the eye of a Cyclopes
                helps understand the rigorous formulas and the language of the giants. This
                overarching superiority made the mages not feel a sense of trying to do
                better but a feeling of malice and envy. Some circles agreed that they are
                not sharing enough of their craft, which the Cyclopes deny and even
                outright enraged by the accusation. However, the Cyclopes’ were forgiving
                individuals. Regardless of cruel intent, they were ready to forgive their
                misgivings. Instead of apologizing, the mages felt like they were being
                taunted. This is why several years later, there was silence between Cyclopes’
                and the Mortals.
                Those years of silence were filled with depravity and cruelty as mages
                defiled the graves of fallen Cyclopes and used their bodies to experiment
                and find the secrets of their magic. This gave birth to the “Catar” curse,
                using the eye of a fallen Cyclopes’ gives birth to a spell that hinders the
                Cyclopes’ capability to leave the Highlands. The curse was an orb of flaming
                purple hue. It was exceptionally bright for Mortals, but the effects were
                devastating for the Highlanders. The mages knew that there was something
                else in that spell, and they needed to test it thoroughly. So they did, in the
                guise of an apology, several mages offered the different Highlander kingdoms
                a “gift” of apology. It was a chest claimed to contain magical artifacts that
                were never seen before. The Cyclopes’ were happy that not only were they willing to apologize, and
                they even went out of their way to offer remuneration for their atonement.
                TheHighlanders didn’t even know that the day of forgiveness would be their
                last day of freedom.
                The second the rulers opened the box, a flame was cast upon the skies as
                rain rained in a purple tempest. The highlanders were surprised by the
                spectacle. They wondered what happened and what was the cause of all this.
                Before the rulers could ask the mages, they disappeared like thin air. The
                rulers sent messengers to the Lowlanders’ kingdoms to explain; they soon
                discovered that none could ever leave their kingdoms. No matter how hard
                they try, even with their special magic. All they have now is themselves and
                the eerie purple dome that stares at them.</p>
            </div>
          </div>
          <div className="lore-chapters">
            <div className="chapter">
              <h3 data-aos="fade-left">3. Escape</h3>
              <p data-aos="fade-up">Several decades later, the Mortal Lowlands have prospered into a bustling
                city that even rivals the once known Highlands. Humanity and their magic
                flourished in their own merit. As time went on, the existence of the Cyclopes
                was non-existent and even taboo to talk about. It seems that’s where the
                story ends, for humanity at least. For years the five Highland Kingdoms
                struggled to keep things afloat. Being sealed in their respective kingdoms
                means struggling to find resources to sustain themselves in their prison of
                an empire. Each of the five domains was already wavering and began
                accepting their fate. Their once beautiful home began to tear apart due to
                conflicts and fighting over resources. Their weakened magic didn’t help their
                situation. The only thing it could do was provide warmth and find ways to
                break the curse. Their magic way was to provide solace in these trying times,
                but their efforts were soon rewarded with a shining hope. Each of the Five
                Kingdoms held heirs that were highly proficient in magic. All five of them
                were skilled enough to break the curse laid upon them.</p>
            </div>
            <div className="chapter">
              <h3 data-aos="fade-left">4. Revenge</h3>
              <p data-aos="fade-up">Once out of the curse, they looked upon the Lowland’s glorious kingdom. It
                glimmered in the morning sun. Almost like a Utopia, they looked satisfied with
                their progress. The Highlanders could have felt proud, happier even that
                their other half now became successful like them. But they couldn’t even feel
                a single joy, nor was there a single smile plastered on their faces. All of
                them were seething in rage; bitterness stirred in their hearts as the
                memories of deception still lingered freshly in their minds. All of the
                unnecessary death of their people due to their own pettiness was all in
                vain. Now all they want is not peace nor unity, only revenge. The five
                Cyclopes’ that broke the curse sent each other messages to meet at the
                highest peak of the Highlands. It was a cruel sight that each strong kingdom
                and many by their standards are whittled down into the hundreds. For now,
                they wait, lurking in the mountain tops where no one will disturb them. They
                will build themselves up from the ashes, not for the sake of progression, but
                for war.
              </p>
            </div>
          </div>
        </div>


      </div>

          <footer>
         
            <p>Copyright © Solana Cyclopes 2022</p>
          </footer>

    </div>
  );
};

export default HomePage;
