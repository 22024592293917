import React, { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import ContactUs from './ContactUs';
import Aos from 'aos';
import 'aos/dist/aos.css';
const useStyles = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
    background: 'transparent',
    border: 'none',
  }


})
);
function toggleMenu() {
  const menu = document.getElementById("mobileNavContainer")!;
  menu.classList.toggle("open-menu");
  console.log("pressed");

}
function TalentAgency() {
  let navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classes = useStyles();
  return (
    <div>
     <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
        </div>
        <ul>

          <li>
            <a onClick={() => {
              navigate("/");
            }}>Home</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/talent-agency");
            }}>Talent Agency</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/marketplace");
            }}>Marketplace</a>
          </li>
          <li>
            <a onClick={() => {
              navigate("/auctions");
            }}>Auctions</a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://twitter.com/CyclopesSolana" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
            
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          <a href="/"><img className="nav-logo" src="/img/logo.png" alt="" /></a>
          <a className ="hide-800" onClick={() => {
            navigate("/");
          }}>Home</a>
          <a className ="hide-800" onClick={() => {
            navigate("/talent-agency");
          }}>Talent Agency</a>
          <a className ="hide-800" onClick={() => {
            navigate("/marketplace");
          }}>Marketplace</a>
          <a className ="hide-800" onClick={() => {
            navigate("/auctions");
          }}>Auctions</a>
          <div className="social-icons hide-800">
            <a href="https://twitter.com/CyclopesSolana" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
          

          </div>
        </div>
      </nav>
      <div className="content-wrapper">
        <div className="introduction">

          <div className="introduction-text">
            <h1>About us</h1>
            <hr className="introduction-hr"></hr>
            <br></br>
            <p>Our end goal is to have a competitive and scalable platform for artists and collectors to safely trade, mint and sell art. In order to ensure that our cooperation will be beneficial to both parties we are looking for the artists who wish to enter or grow in the NFT space but lack the technical and marketing expertise to do so.<br></br><br></br>
            In particular, three categories of artists can be identified that would be interested in our launchpad: 1/1 artists who have some experience selling their art online, however, do not know not how to create a generative collection; artists who have a background in the traditional art industry and wish to enter the NFT space; and 1/1 artists with some level of experience and small audience who wish to scale up and reach new heights.</p>

          </div>

          <div className="gallery">
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/1.gif"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/1.png"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/2.gif"></img>

                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/2.png"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/3.gif"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/3.png"></img>

                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/4.gif"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/4.png"></img>

                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper className={classes.paper}>
                  <img src="/assets/5.gif"></img>

                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="lookingfor">
          <h1>Who are we looking for?</h1>
          <div className="lookingfor-container">
            <div data-aos="fade-down-right" className="minicard">
            <div className="container-hover">
            Artists looking to enter the NFT space
                  <div className="overlay2">
                    <div className="text2 _1">You have the desire to enter the digital art and NFT space however don’t know how or where to start.</div>
                  </div>
                </div>
             
            </div>
            <div data-aos="flip-up" className="minicard">
            <div className="container-hover">
            Digital artists looking to outsource background processes
                  <div className="overlay2">
                    <div className="text2">You can focus solely on the creative and artistic output without worrying about marketing, technical and communication aspects</div>
                  </div>
                </div>
              
            </div>
            <div data-aos="fade-down-left" className="minicard">
            <div className="container-hover">
            NFT artists for a way to scale up
                  <div className="overlay2">
                    <div className="text2">Multiple in-house experts to fully support your desire to grow and reach your full creative potential</div>
                  </div>
                </div>

            </div>
          </div>
        </div>
          <div className="lookingfor">
          <h1>Why work with us?</h1>
          <div className="lookingfor-container">
            <div data-aos="fade-down-right" className="minicard __2">
            <div className="container-hover">
              Marketing and content strategy for twitter/discord
                  <div className="overlay2">
                    <div className="text2 _1">
                      <ul>
                        <li>Collaborations and influencer outreach</li>
                        <li>Support from the community of Solana Cyclopes and our partners</li>
                        <li>PR & communication</li>
                      </ul>
                    </div>
                  </div>
                </div>
            
            </div>
            <div data-aos="fade-down-left" className="minicard __2">
            <div className="container-hover">
                Technical assistance for generative collection
                  <div className="overlay2">
                    <div className="text2 _1">
                    <ul>
                        <li>Metadata and art generation</li>
                        <li>Landing and minting website design and development</li>
                        <li>Assistance with listings on relevant marketplaces</li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
            </div>
            <div className="lookingfor-container">
            <div data-aos="fade-up-right" className="minicard __2">
            <div className="container-hover">
                  Creative input
                  <div className="overlay2">
                    <div className="text2 mt _2 mmt">
                    <ul>
                        <li>Assistance in the research of the current market environment to ensure product-market fit</li>
                        <li>Access to the network of other artists from the launchpad</li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
            <div data-aos="fade-up-left" className="minicard __2">
            <div className="container-hover">
                  Post mint support
                  <div className="overlay2">
                    <div className="text2 mt _3">
                    <ul>
                        <li>Help with establishing the utility profile of the project, in particular, with art projects community centered features</li>

                      </ul>
                    </div>
                  </div>
                </div>
            </div>
          </div>



         
          </div>

          <div className="greek">
            <h1>Greek Creatures Collection</h1>
            <hr></hr>
            <p data-aos="flip-right">As part of our Talent Agency venture we will be releasing an exclusive 1/1 collection “Greek Creatures” with a total of 50 pieces each one designed by a different artist in their own style. We expect it to be extremely diverse starting from 3d art to 8 bit pixel art. We want the artists to have the creative freedom so they are only bound by the common theme of Greek mythology. If you are an artist and want to be part of the project, fill in the form below. You will receive our full support on the marketing, management, technical and utility aspects along with 70% of the auction proceeds.</p>
          </div>

<ContactUs/>

      </div>

      <footer>
        <p>Copyright © Solana Cyclopes 2022</p>
      </footer>

    </div>
  );
}
export default TalentAgency;